<template>
    <div>
        <CRow>
            <CCol col="12" xl="12">
                <CCard>
                    <CDataTable
                        :items="refundLogs"
                        :fields="fields"
                        :items-per-page="10"
                        hover
                        fixed
                        :loading="loading"
                        clickable-rows
                        pagination
                        v-on:refresh="refresh(params.paymentLogId)"
                        class="modalTable"
                    >
                        <template #createTime="{item}">
                            <td> <span>{{ dateFormat(item.createTime) }}</span> </td>
                        </template>
                        <template #log="{item}">
                            <td @click="openModalEvent('confirm', 'confirm', item, dateFormat(item.createTime) +' - Tarihli iade Logu', item.log)"> 
                                <p v-if="item.log.length > 100"> {{ item.log.substring(0,100) + '...'}}</p>
                                <p v-else> {{ item.log }} </p> 
                            </td>
                        </template>
                    </CDataTable>
                </CCard>
            </CCol>
        </CRow>
        <ConfirmModal
            :show="openModal" 
            :title="modalTitle" 
            :openModalEvent="openModalEvent"
            :desc="modalDesc"
            :data="modalProps"
            :actionType="actionType"
            :loading="loading"
            size="lg"
            :noFooter="true"
        />
    </div>
</template>

<script>
    import moment from 'moment'
    import ConfirmModal from '../../components/confirmModal'

    export default{
        name: "RefundLogList",
        components: {ConfirmModal},
        props:{
            params: Object,
        },
        computed:{
            refundLogs: function() {
                return this.$store.getters.orderRefundLog
            },
            loading(){
                return this.$store.getters.orderLoading
            }
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
        },
        data: function(){
            return{
                openModal: false,
                actionType: 'confirm',
                form: 'confirm',
                modalProps: {},
                modalTitle: '',
                modalDesc: '',
                fields: [
                    {key: 'createTime', label: 'İade_Tarihi', _style: 'font-size:12px'},
                    {key: 'amount', label: 'İade_Tutarı', _style: 'font-size:12px'},
                    {key: 'ipAddress', label: 'IP_Adresi', _style: 'font-size:12px'},
                    {key: 'log', label: 'LOG', _style: 'font-size:12px'},
                ],
            }
        },
        methods: {
            refresh: function(id){
                this.$store.dispatch('order_refundLogs', {paymentLogId: id})
            },
            openModalEvent(actionType, form, data, title, desc){
                this.openModal = !this.openModal
                this.actionType = actionType
                this.form = form
                this.modalProps = data
                this.modalTitle = title
                this.modalDesc = desc
            },
            dateFormat: function(date){
                return moment(date).format('DD.MM.YYYY HH:mm:ss')
            },
        }
    }
</script>
<style scoped>
     .table-responsive{
        height: auto;
    }
</style>